export default [
  {
    path: "/objective",
    name: "list-objective",
    component: () => import("@/views/objective/List.vue"),
    meta: {
      pageTitle: "breadcrumb.objective",
      resource: "objective",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listObjective",
          active: true,
        },
      ],
    },
  },
  {
    path: "/objective/create",
    name: "create-objective",
    component: () => import("@/views/objective/Create.vue"),
    meta: {
      pageTitle: "breadcrumb.objective",
      navActiveLink: "list-objective",
      resource: "objective",
      action: "create",
      breadcrumb: [
        {
          text: "breadcrumb.listObjective",
          to: { name: "list-objective" },
        },
        {
          text: "breadcrumb.createObjective",
          active: true,
        },
      ],
    },
  },
  {
    path: "/objective/:id/update",
    name: "update-objective",
    component: () => import("@/views/objective/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.objective",
      navActiveLink: "list-objective",
      resource: "objective",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.listObjective",
          to: { name: "list-objective" },
        },
        {
          text: "breadcrumb.updateObjective",
          active: true,
        },
      ],
    },
  },
];
