import Vue from 'vue';
import currency from 'currency.js'

Vue.filter('currency', function (value, symbol = "", precision = 2) {
    if (typeof value === 'undefined') {
        return '';
    }

    if (symbol == "%") {
        const C = value => currency(value, { precision: precision, symbol: "" });
        return C(value).format() + symbol;
    }

    const C = value => currency(value, { precision: precision, symbol: symbol });
    return C(value).format();
});
