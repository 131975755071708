import moment from 'moment';
import currency from "currency.js";
import { decamelize, camelize } from 'humps';
import store from '@/store';
import state from "@/data/state";

export const caclulateOffset = (page, limit) => {
  return (page - 1) * limit;
};

export const calculateDatesByPeriod = (period) => {
  if (period === 'today') {
    return [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
  } else if (period === 'yesterday') {
    return [moment().subtract(1, 'day').format('YYYY-MM-DD'), moment().subtract(1, 'day').format('YYYY-MM-DD')];
  } else if (period === 'thisWeek') {
    return [moment().startOf('isoWeek').format('YYYY-MM-DD'), moment().endOf('isoWeek').format('YYYY-MM-DD')];
  } else if (period === 'lastWeek') {
    return [moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'), moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')];
  } else if (period === 'thisMonth') {
    return [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')];
  } else if (period === 'lastMonth') {
    return [moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')];
  }

  return [];
}
export const currencyFormat = (value, currencyCode) => {
  if (typeof value === 'undefined') {
    return '';
  }
  let val = value;
  let symbol = '';

  if (currencyCode === 840) {
    const USD = value => currency(value);
    return USD(value).format();
  } else if (currencyCode === 116) {
    const RIEL = value => currency(value, { precision: 2, symbol: '៛' });
    return RIEL(value).format()
  }

  return currency(value);
}
export const prepareSearchParams = (payload) => {
  const params = {
    ...payload,
    limit: store.state.pagination.perPage,
    page: payload.page,
    sortby: payload.order || "id",
    sort: payload.sort || "desc",
    archived: payload.archived ? 1 : 0,
  };

  return params;
}

export const makeid = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}

export const mapAbility = (data, newAbility, initialAbility) => {
  if (data.isReadonly) {
    data.ability.forEach((x) => {
      x.action = "read";
      return x;
    });
  }

  newAbility = [...data.ability, ...initialAbility];
  return newAbility;
}

export const getTranslate = (data, field, lang = "en") => {
  if(data[camelize(`${field}_km`)] == null) {
    field = camelize(`${field}_en`);
  } else {
    field = camelize(`${field}_${lang}`);
  }

  return data[field];
};

export const convertToDouble = (value) => {
  if(!value) {
    return "0.00";
  }
  
  return Number.parseFloat(value).toFixed(2);
}

export const getState = (value) => {
  const state = {
    rejected: "rejected",
    draft: "draft",
    submitted: "submitted",
    approved: "approved",
    hodapproved: "hodapproved",
    hodrejected: "hodrejected",
    gmapproved: "gmapproved",
    gmrejected: "gmrejected",
    hrapproved: "hrapproved",
    hrrejected: "hrrejected",
    managementapproved: "managementapproved",
    managementrejected: "managementrejected",
    notstarted: "notstarted",
  };

  if(value === state.rejected) {
    return {
      name: state.rejected,
      color: "danger"
    };
  } else if(value === state.draft) {
    return {
      name: state.draft,
      color: "light-dark"
    };
  } else if(value === state.submitted) {
    return {
      name: state.submitted,
      color: "submitted"
    };
  } else if(value === state.approved) {
    return {
      name: state.approved,
      color: "supervisor-approved"
    };
  } else if(value === state.hodrejected) {
    return {
      name: state.hodrejected,
      color: "danger"
    };
  } else if(value === state.hodapproved) {
    return {
      name: state.hodapproved,
      color: "hod-approved"
    };
  } else if(value === state.gmrejected) {
    return {
      name: state.gmrejected,
      color: "danger"
    };
  } else if(value === state.gmapproved) {
    return {
      name: state.gmapproved,
      color: "gm-approved"
    };
  } else if(value === state.hrrejected) {
    return {
      name: state.hrrejected,
      color: "danger"
    };
  } else if(value === state.hrapproved) {
    return {
      name: state.hrapproved,
      color: "hr-approved"
    };
  } else if(value === state.managementrejected) {
    return {
      name: state.managementrejected,
      color: "danger"
    };
  } else if(value === state.managementapproved) {
    return {
      name: state.managementapproved,
      color: "management-approved"
    };
  } else {
    return {
      name: state.notstarted,
      color: "secondary"
    }
  }
}

export const getMidYearStateColor = (value) => {
  const state = {
    pending: "pending",
    ontrack: "on",
    offtrack: "off"
  };

  if(value.toLowerCase().includes(state.offtrack)) {
    return "danger";
  } else if(value.toLowerCase().includes(state.pending)) {
    return "warning";
  } else if(value.toLowerCase().includes(state.ontrack)) {
    return "success";
  }
}

export const getYearEndStateColor = (value) => { 
  const state = {
    achieved: "achieved",
    under: "under",
    ongoing: "ongoing"
  };

  if(value.toLowerCase().includes(state.under)) {
    return "danger";
  } else if(value.toLowerCase().includes(state.ongoing)) {
    return "warning";
  } else if(value.toLowerCase().includes(state.achieved)) {
    return "success";
  }
}

export const getYearEndOverallRatingColor = (value) => {
  value = parseFloat(value).toFixed(2);
  if(value >= 97.00 && value <= 100.00) {
    return {
      key: "exceptional",
      color: "bg-exceptional",
    };
  } else if(value >= 91.00 && value <= 96.99) {
    return {
      key: "superior",
      color: "bg-superior",
    };
  } else if(value >= 70.00 && value <= 90.99) {
    return {
      key: "satisfactory",
      color: "bg-satisfactory",
    };
  } else if(value >= 50.00 && value <= 69.99) {
    return {
      key: "improvementrequired",
      color: "bg-improvementrequired",
    };
  } else {
    return {
      key: "unacceptable",
      color: "bg-unacceptable",
    };
  }
}

export const canHrReview = (value) => {
  return state.submitted !== value && state.approved !== value && state.rejected !== value && 
    state.hodapproved !== value && state.hodrejected !== value && 
    state.gmrejected !== value &&
    state.hrapproved !== value && state.hrrejected !== value &&
    state.managementapproved !== value && state.managementrejected !== value;
}

export const canHrReviewWithoutGm = (value) => {
  return state.submitted !== value && state.approved !== value && state.rejected !== value && 
    state.hodrejected !== value &&
    state.gmapproved !== value && state.gmrejected !== value &&
    state.hrapproved !== value && state.hrrejected !== value &&
    state.managementapproved !== value && state.managementrejected !== value;
}

export const canManagementReview = (value) => {
  return state.submitted !== value && state.approved !== value && state.rejected !== value && 
    state.hodrejected !== value && state.hodapproved !== value &&
    state.gmrejected !== value && state.gmapproved !== value &&
    state.hrrejected !== value &&
    state.managementapproved !== value && state.managementrejected !== value;
}

export const canHrExport = (value) => {
  return state.hrapproved === value || state.managementapproved === value || state.managementrejected === value;
}

export const canHrComment = (value) => {
  return state.hrapproved !== value && state.managementapproved !== value && state.managementrejected !== value;
}

export const canHrSignature = (value) => {
  return value.toLowerCase().includes('hr', 'hrm');
}