export default [
  {
    path: "/configuration",
    name: "list-configuration",
    component: () => import("@/views/configuration/List.vue"),
    meta: {
      pageTitle: "breadcrumb.configuration",
      resource: "configuration",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listConfiguration",
          active: true,
        },
      ],
    },
  },
  {
    path: "/configuration/:id/update",
    name: "update-configuration",
    component: () => import("@/views/configuration/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.configuration",
      navActiveLink: "list-configuration",
      resource: "configuration",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.listConfiguration",
          to: { name: "list-configuration" },
        },
        {
          text: "breadcrumb.updateConfiguration",
          active: true,
        },
      ],
    },
  },
];
