export default {
  rejected: "rejected",
  draft: "draft",
  pending: "pending",
  submitted: "submitted",
  approved: "approved",
  hodapproved: "hodapproved",
  hodrejected: "hodrejected",
  gmapproved: "gmapproved",
  gmrejected: "gmrejected",
  hrapproved: "hrapproved",
  hrrejected: "hrrejected",
  managementapproved: "managementapproved",
  managementrejected: "managementrejected",
  notstarted: "notstarted",
};
