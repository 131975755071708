import Client from "@/repositories/Clients/AxiosClient";
import Config from "@/repositories/Clients/AxiosDefaultConfig";
import { prepareSearchParams } from "@/libs/helper";

const resource = `${Config.basePath}/v1/admin/dashboard`;

export default {
  summary(payload) {
    return Client.get(resource, { params: payload });
  },
  chart(payload) {
    return Client.get(`${resource}/chart`, { 
      params: prepareSearchParams(payload) 
    });
  },
};
