export default [
  {
    path: "/step-information",
    name: "update-step-information",
    component: () => import("@/views/stepInformation/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.stepInformation",
      resource: "step-information",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.stepInformation",
          active: true,
        },
      ],
    },
  },
];
