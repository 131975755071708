export default [
  {
    path: '/administrator',
    name: 'list-user',
    component: () => import('@/views/user/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.administrator',
      resource: 'user',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listAdministrator',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administrator/create',
    name: 'create-user',
    component: () => import('@/views/user/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.administrator',
      navActiveLink: 'list-user',
      resource: 'user',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listAdministrator',
          to: { name: 'list-user' }
        },
        {
          text: 'breadcrumb.createAdministrator',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administrator/:id/update',
    name: 'update-user',
    component: () => import('@/views/user/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.administrator',
      navActiveLink: 'list-user',
      resource: 'user',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listAdministrator',
          to: { name: 'list-user' }
        },
        {
          text: 'breadcrumb.updateAdministrator',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administrator/:id',
    name: 'view-user',
    component: () => import('@/views/user/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.administrator',
      navActiveLink: 'list-user',
      resource: 'user',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listAdministrator',
          to: { name: 'list-user' }
        },
        {
          text: 'breadcrumb.viewAdministrator',
          active: true,
        },
      ],
    },
  },
]
