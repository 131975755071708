export default [
  {
    path: "/objective-type",
    name: "list-objective-type",
    component: () => import("@/views/objectiveType/List.vue"),
    meta: {
      pageTitle: "breadcrumb.objectiveType",
      resource: "objective-type",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listObjectiveType",
          active: true,
        },
      ],
    },
  },
  {
    path: "/objective-type/create",
    name: "create-objective-type",
    component: () => import("@/views/objectiveType/Create.vue"),
    meta: {
      pageTitle: "breadcrumb.objectiveType",
      navActiveLink: "list-objective-type",
      resource: "objective-type",
      action: "create",
      breadcrumb: [
        {
          text: "breadcrumb.listObjectiveType",
          to: { name: "list-objective-type" },
        },
        {
          text: "breadcrumb.createObjectiveType",
          active: true,
        },
      ],
    },
  },
  {
    path: "/objective-type/:id/update",
    name: "update-objective-type",
    component: () => import("@/views/objectiveType/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.objectiveType",
      navActiveLink: "list-objective-type",
      resource: "objective-type",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.listObjectiveType",
          to: { name: "list-objective-type" },
        },
        {
          text: "breadcrumb.updateObjectiveType",
          active: true,
        },
      ],
    },
  },
];
