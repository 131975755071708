import ProfileRepository from "./Api/ProfileRepository";
import UserRepository from "./Api/UserRepository";
import RoleRepository from "./Api/RoleRepository";
import PermissionRepository from "./Api/PermissionRepository";
import FileRepository from "./Api/FileRepository";
import CompanyRepository from "./Api/CompanyRepository";
import DepartmentRepository from "./Api/DepartmentRepository";
import PositionRepository from "./Api/PositionRepository";
import EmployeeRepository from "./Api/EmployeeRepository";
import ObjectiveTypeRepository from "./Api/ObjectiveTypeRepository";
import ObjectiveRepository from "./Api/ObjectiveRepository";
import MidYearReviewStatusRepository from "./Api/MidYearReviewStatusRepository";
import MidYearReviewCommentQuestionRepository from "./Api/MidYearReviewCommentQuestionRepository";
import YearEndReviewStatusRepository from "./Api/YearEndReviewStatusRepository";
import YearEndReviewCommentQuestionRepository from "./Api/YearEndReviewCommentQuestionRepository";
import YearEndReviewConsiderQuestionRepository from "./Api/YearEndReviewConsiderQuestionRepository";
import OkrSettingRepository from "./Api/OkrSettingRepository";
import StepInformationRepository from "./Api/StepInformationRepository";
import YearEndReviewSignatureLabelRepository from "./Api/YearEndReviewSignatureLabelRepository";
import YearEndReviewSignatureRepository from "./Api/YearEndReviewSignatureRepository";
import YearEndReviewRepository from "./Api/YearEndReviewRepository";
import MidYearReviewRepository from "./Api/MidYearReviewRepository";
import DashboardRepository from "./Api/DashboardRepository";
import ConfigurationRepository from "./Api/ConfigurationRepository";

const repositories = {
    'profile': ProfileRepository,
    'user': UserRepository,
    'role': RoleRepository,
    'permission': PermissionRepository,
    'file': FileRepository,
    'company': CompanyRepository,
    'department': DepartmentRepository,
    'position': PositionRepository,
    'employee': EmployeeRepository,
    'objectiveType': ObjectiveTypeRepository,
    'objective': ObjectiveRepository,
    'midYearReviewStatus': MidYearReviewStatusRepository,
    'midYearReviewCommentQuestion': MidYearReviewCommentQuestionRepository,
    'yearEndReviewStatus': YearEndReviewStatusRepository,
    'yearEndReviewCommentQuestion': YearEndReviewCommentQuestionRepository,
    'yearEndReviewConsiderQuestion': YearEndReviewConsiderQuestionRepository,
    'okrSetting': OkrSettingRepository,
    'stepInformation': StepInformationRepository,
    'yearEndReviewSignatureLabel': YearEndReviewSignatureLabelRepository,
    'yearEndReviewSignature': YearEndReviewSignatureRepository,
    'yearEndReview': YearEndReviewRepository,
    'midYearReview': MidYearReviewRepository,
    'dashboard': DashboardRepository,
    'configuration': ConfigurationRepository,
};

export default {
    get: name => repositories[name]
};
