export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'auth',
      pageTitle: 'breadcrumb.login',
      redirectIfLoggedIn: true,
    },
  },
]
