export default [
  {
    path: "/position",
    name: "list-position",
    component: () => import("@/views/position/List.vue"),
    meta: {
      pageTitle: "breadcrumb.position",
      resource: "position",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listPosition",
          active: true,
        },
      ],
    },
  },
  {
    path: "/position/create",
    name: "create-position",
    component: () => import("@/views/position/Create.vue"),
    meta: {
      pageTitle: "breadcrumb.position",
      navActiveLink: "list-position",
      resource: "position",
      action: "create",
      breadcrumb: [
        {
          text: "breadcrumb.listPosition",
          to: { name: "list-position" },
        },
        {
          text: "breadcrumb.createPosition",
          active: true,
        },
      ],
    },
  },
  {
    path: "/position/:id/update",
    name: "update-position",
    component: () => import("@/views/position/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.position",
      navActiveLink: "list-position",
      resource: "position",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.listPosition",
          to: { name: "list-position" },
        },
        {
          text: "breadcrumb.updatePosition",
          active: true,
        },
      ],
    },
  },
];
