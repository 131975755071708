export default [
  {
    path: "/okr-setting",
    name: "list-okr-setting",
    component: () => import("@/views/okrSetting/List.vue"),
    meta: {
      pageTitle: "breadcrumb.okrSetting",
      resource: "okr-setting",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listOkrSetting",
          active: true,
        },
      ],
    },
  },
  {
    path: "/okr-setting/create",
    name: "create-okr-setting",
    component: () => import("@/views/okrSetting/Create.vue"),
    meta: {
      pageTitle: "breadcrumb.okrSetting",
      navActiveLink: "list-okr-setting",
      resource: "okr-setting",
      action: "create",
      breadcrumb: [
        {
          text: "breadcrumb.listOkrSetting",
          to: { name: "list-okr-setting" },
        },
        {
          text: "breadcrumb.createOkrSetting",
          active: true,
        },
      ],
    },
  },
  {
    path: "/okr-setting/:id/update",
    name: "update-okr-setting",
    component: () => import("@/views/okrSetting/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.okrSetting",
      navActiveLink: "list-okr-setting",
      resource: "okr-setting",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.listOkrSetting",
          to: { name: "list-okr-setting" },
        },
        {
          text: "breadcrumb.updateOkrSetting",
          active: true,
        },
      ],
    },
  },
  {
    path: "/okr-setting/:id/clone",
    name: "clone-okr-setting",
    component: () => import("@/views/okrSetting/Clone.vue"),
    meta: {
      pageTitle: "breadcrumb.okrSetting",
      navActiveLink: "list-okr-setting",
      resource: "okr-setting",
      action: "clone",
      breadcrumb: [
        {
          text: "breadcrumb.listOkrSetting",
          to: { name: "list-okr-setting" },
        },
        {
          text: "breadcrumb.cloneOkrSetting",
          active: true,
        },
      ],
    },
  },
  {
    path: "/okr-setting/:id/review",
    name: "review-okr-setting",
    component: () => import("@/views/okrSetting/View.vue"),
    meta: {
      pageTitle: "breadcrumb.okrSetting",
      navActiveLink: "list-okr-setting",
      resource: "okr-setting",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listOkrSetting",
          to: { name: "list-okr-setting" },
        },
        {
          text: "breadcrumb.reviewOkrSetting",
          active: true,
        },
      ],
    },
  },
];
