import Client from "@/repositories/Clients/AxiosClient";
import Config from "@/repositories/Clients/AxiosDefaultConfig";

const resource = `${Config.basePath}/v1/admin/step_information`;

export default {
  show(payload) {
    return Client.get(resource, {
      params: payload,
    });
  },
  create(payload) {
    return Client.post(resource, payload);
  },
  update(id, payload) {
    return Client.put(`${resource}/${id}`, payload);
  },
};
