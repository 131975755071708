export default [
  {
    path: "/year-end-review-status",
    name: "list-year-end-review-status",
    component: () => import("@/views/yearEndReviewStatus/List.vue"),
    meta: {
      pageTitle: "breadcrumb.status",
      resource: "year-end-review-status",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.status",
          active: true,
        },
      ],
    },
  },
  {
    path: "/year-end-review-status/create",
    name: "create-year-end-review-status",
    component: () => import("@/views/yearEndReviewStatus/Create.vue"),
    meta: {
      pageTitle: "breadcrumb.status",
      navActiveLink: "list-year-end-review-status",
      resource: "year-end-review-status",
      action: "create",
      breadcrumb: [
        {
          text: "breadcrumb.listStatus",
          to: { name: "list-year-end-review-status" },
        },
        {
          text: "breadcrumb.createStatus",
          active: true,
        },
      ],
    },
  },
  {
    path: "/year-end-review-status/:id/update",
    name: "update-year-end-review-status",
    component: () => import("@/views/yearEndReviewStatus/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.status",
      navActiveLink: "list-year-end-review-status",
      resource: "year-end-review-status",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.listStatus",
          to: { name: "list-year-end-review-status" },
        },
        {
          text: "breadcrumb.updateStatus",
          active: true,
        },
      ],
    },
  },
];
