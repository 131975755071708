export default [
  {
    path: "/mid-year-review-comment-question",
    name: "list-mid-year-review-comment-question",
    component: () => import("@/views/midYearReviewCommentQuestion/List.vue"),
    meta: {
      pageTitle: "breadcrumb.commentQuestion",
      resource: "mid-year-review-comment-question",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.commentQuestion",
          active: true,
        },
      ],
    },
  },
  {
    path: "/mid-year-review-comment-question/create",
    name: "create-mid-year-review-comment-question",
    component: () => import("@/views/midYearReviewCommentQuestion/Create.vue"),
    meta: {
      pageTitle: "breadcrumb.commentQuestion",
      navActiveLink: "list-mid-year-review-comment-question",
      resource: "mid-year-review-comment-question",
      action: "create",
      breadcrumb: [
        {
          text: "breadcrumb.listCommentQuestion",
          to: { name: "list-mid-year-review-comment-question" },
        },
        {
          text: "breadcrumb.createCommentQuestion",
          active: true,
        },
      ],
    },
  },
  {
    path: "/mid-year-review-comment-question/:id/update",
    name: "update-mid-year-review-comment-question",
    component: () => import("@/views/midYearReviewCommentQuestion/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.commentQuestion",
      navActiveLink: "list-mid-year-review-comment-question",
      resource: "mid-year-review-comment-question",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.listCommentQuestion",
          to: { name: "list-mid-year-review-comment-question" },
        },
        {
          text: "breadcrumb.updateCommentQuestion",
          active: true,
        },
      ],
    },
  },
];
