export default {
    namespaced: true,
    state: {
        id: null,
        name: null,
        email: null,
        typeId: null,
        roleNames: [],
    },
    getters: {},
    mutations: {
        UPDATE_PROFILE(state, val) {
            state.id = val.id;
            state.name = val.name;
            state.email = val.email;
            state.typeId = val.typeId;
            state.roleNames = val.roleNames;
        },
    },
    actions: {},
}
