import Vue from "vue";
import moment from "moment";
import i18n from "@/libs/i18n";

Vue.filter("formatDate", function (value, format = "LLL") {
  if (!value) {
    return "";
  }
  return moment(value).locale(i18n.locale).format(format);
});
