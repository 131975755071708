import Vue from 'vue';
import i18n from "@/libs/i18n";
import VueRouter from 'vue-router';
import { $themeConfig } from "@themeConfig";

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import errors from './routes/errors';
import auth from './routes/auth';
import profile from './routes/profile';
import user from './routes/user';
import role from './routes/role';
import company from './routes/company';
import department from './routes/department';
import position from './routes/position';
import employee from './routes/employee';
import objectiveType from './routes/objectiveType';
import objective from './routes/objective';
import midYearReviewStatus from './routes/midYearReviewStatus';
import midYearReviewCommentQuestion from './routes/midYearReviewCommentQuestion';
import yearEndReviewStatus from './routes/yearEndReviewStatus';
import yearEndReviewCommentQuestion from './routes/yearEndReviewCommentQuestion';
import yearEndReviewConsiderQuestion from './routes/yearEndReviewConsiderQuestion';
import okrSetting from './routes/okrSetting';
import stepInformation from './routes/stepInformation';
import yearEndReviewSignatureLabel from './routes/yearEndReviewSignatureLabel';
import configuration from './routes/configuration';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/dashboard/Dashboard.vue'),
      meta: {
        pageTitle: 'breadcrumb.dashboard',
        resource: 'dashboard',
        breadcrumb: [
          {
            text: 'breadcrumb.dashboard',
            active: true,
          },
        ],
      },
    },
    ...errors,
    ...auth,
    ...profile,
    ...user,
    ...role,
    ...company,
    ...department,
    ...position,
    ...employee,
    ...objectiveType,
    ...objective,
    ...midYearReviewStatus,
    ...midYearReviewCommentQuestion,
    ...yearEndReviewStatus,
    ...yearEndReviewCommentQuestion,
    ...yearEndReviewConsiderQuestion,
    ...okrSetting,
    ...stepInformation,
    ...yearEndReviewSignatureLabel,
    ...configuration,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// Method using PUSH
const RouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return RouterPush.call(this, to).catch(err => err)
}

// Method using Replace
const RouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
  return RouterReplace.call(this, to).catch(err => err)
}

router.beforeEach((to, _, next) => {
  Vue.nextTick(() => {
    const { appName } = $themeConfig.app;
    document.title = `${to.meta.title ? i18n.tc(to.meta.title) : i18n.tc(to.meta.pageTitle)} | ${appName}`;
  });

  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? 'admin' : null))
  }

  return next()
})

export default router
