export default [
  {
    path: "/year-end-review-signature-label",
    name: "list-year-end-review-signature-label",
    component: () => import("@/views/yearEndReviewSignatureLabel/List.vue"),
    meta: {
      pageTitle: "breadcrumb.signatureLabel",
      resource: "year-end-review-signature-label",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.signatureLabel",
          active: true,
        },
      ],
    },
  },
  {
    path: "/year-end-review-signature-label/create",
    name: "create-year-end-review-signature-label",
    component: () => import("@/views/yearEndReviewSignatureLabel/Create.vue"),
    meta: {
      pageTitle: "breadcrumb.signatureLabel",
      navActiveLink: "list-year-end-review-signature-label",
      resource: "year-end-review-signature-label",
      action: "create",
      breadcrumb: [
        {
          text: "breadcrumb.listSignatureLabel",
          to: { name: "list-year-end-review-signature-label" },
        },
        {
          text: "breadcrumb.createSignatureLabel",
          active: true,
        },
      ],
    },
  },
  {
    path: "/year-end-review-signature-label/:id/update",
    name: "update-year-end-review-signature-label",
    component: () => import("@/views/yearEndReviewSignatureLabel/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.signatureLabel",
      navActiveLink: "list-year-end-review-signature-label",
      resource: "year-end-review-signature-label",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.listSignatureLabel",
          to: { name: "list-year-end-review-signature-label" },
        },
        {
          text: "breadcrumb.updateSignatureLabel",
          active: true,
        },
      ],
    },
  },
];
