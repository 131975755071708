export default [
  {
    path: "/year-end-review-consider-question",
    name: "list-year-end-review-consider-question",
    component: () => import("@/views/yearEndReviewConsiderQuestion/List.vue"),
    meta: {
      pageTitle: "breadcrumb.considerQuestion",
      resource: "year-end-review-consider-question",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.considerQuestion",
          active: true,
        },
      ],
    },
  },
  {
    path: "/year-end-review-consider-question/create",
    name: "create-year-end-review-consider-question",
    component: () => import("@/views/yearEndReviewConsiderQuestion/Create.vue"),
    meta: {
      pageTitle: "breadcrumb.considerQuestion",
      navActiveLink: "list-year-end-review-consider-question",
      resource: "year-end-review-consider-question",
      action: "create",
      breadcrumb: [
        {
          text: "breadcrumb.listConsiderQuestion",
          to: { name: "list-year-end-review-consider-question" },
        },
        {
          text: "breadcrumb.createConsiderQuestion",
          active: true,
        },
      ],
    },
  },
  {
    path: "/year-end-review-consider-question/:id/update",
    name: "update-year-end-review-consider-question",
    component: () => import("@/views/yearEndReviewConsiderQuestion/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.considerQuestion",
      navActiveLink: "list-year-end-review-consider-question",
      resource: "year-end-review-consider-question",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.listConsiderQuestion",
          to: { name: "list-year-end-review-consider-question" },
        },
        {
          text: "breadcrumb.updateConsiderQuestion",
          active: true,
        },
      ],
    },
  },
];
