import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/admin/me`;

export default {
    getProfile(payload = {}) {
        return Client.get(resource, {
            params: payload
        });
    },
    updateProfile(payload) {
        return Client.put(resource, payload);
    },
    updatePassword(payload) {
        return Client.put(`${resource}/password`, payload);
    },
};
