import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/admin/permission`;

export default {
  index(payload) {
    return Client.get(`${resource}/option`, {
      params: payload,
    });
  },
};
